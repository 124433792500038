<template>
    <v-dialog v-model="dialog" width="500">
      <v-card style="display: flex; flex-direction: column;">
        <!-- Fixed Header -->
        <v-card-title class="white--text primary">
          Edit Team Name
        </v-card-title>
  
        <!-- Content -->
        <v-card-text class="pa-4">
          <v-text-field
            v-model="teamName"
            label="Team Name"
            outlined
            hide-details
            @keyup.enter="confirm"
          ></v-text-field>
        </v-card-text>
  
        <!-- Fixed Footer -->
        <v-card-actions class="pt-2">
          <v-spacer></v-spacer>
          <v-btn @click="cancel">Cancel</v-btn>
          <v-btn 
            color="primary" 
            :disabled="!teamName"
            @click="confirm"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'EditTeamDialog',
    
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      currentName: {
        type: String,
        required: true,
      },
    },
  
    data() {
      return {
        teamName: '',
      }
    },
  
    computed: {
      dialog: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
    },
  
    watch: {
      value(newVal) {
        if (newVal) {
          this.teamName = this.currentName
        }
      },
    },
  
    methods: {
      confirm() {
        this.$emit('confirm', this.teamName)
      },
      cancel() {
        this.dialog = false
        this.$emit('cancel')
      },
    },
  }
  </script>
  