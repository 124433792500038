<template>
    <v-dialog v-model="dialog" width="500">
      <v-card style="display: flex; flex-direction: column;">
        <!-- Fixed Header -->
        <v-card-title class="white--text primary">
          Delete Team - {{ teamName }}
        </v-card-title>
  
        <!-- Content -->
        <v-card-text class="pa-4">
          <div class="text-subtitle-1">
            Are you sure you want to delete <strong>{{ teamName }}</strong>? This action cannot be undone.
          </div>
        </v-card-text>
  
        <!-- Fixed Footer -->
        <v-card-actions class="pt-2">
          <v-spacer></v-spacer>
          <v-btn @click="cancel">Cancel</v-btn>
          <v-btn 
            color="error" 
            @click="confirm"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'DeleteTeamDialog',
    
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      teamName: {
        type: String,
        required: true,
      }
    },
  
    computed: {
      dialog: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
    },
  
    methods: {
      confirm() {
        this.$emit('confirm')
      },
      cancel() {
        this.dialog = false
        this.$emit('cancel')
      },
    },
  }
  </script>
  