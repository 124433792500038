<!-- dialogs/AddTeamMemberDialog.vue -->
<template>
  <v-dialog v-model="dialogModel" width="1000">
    <v-card style="display: flex; flex-direction: column; max-height: 90vh;">
      <!-- Fixed Header -->
      <v-card-title class="white--text primary">
        Add Team Member
      </v-card-title>

      <!-- Fixed Search Bar -->
      <v-card-text class="pa-4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          clearable
          label="Search Users"
          single-line
          outlined
          hide-details
          @click:clear="search = ''"
        />
      </v-card-text>

      <!-- Scrollable Table Container -->
      <v-card-text class="overflow-y-auto pa-0" style="margin-top:10px;max-height: 60vh;">
        <v-data-table
          v-model="selectedUsers"
          :headers="headers"
          :items="filteredContactCards"
          :search="search"
          show-select
          item-key="contactCard.id"
          fixed-header
        >
          <template v-slot:item.image="{ item }">
            <div class="ma-2">
              <v-avatar size="85">
                <img :src="getUserAvatar(item.contactCard.profilePicture)">
              </v-avatar>
            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <!-- Fixed Footer -->
      <v-card-actions class="pt-2">
        <v-spacer></v-spacer>
        <v-btn @click="dialogModel = false">Cancel</v-btn>
        <v-btn 
          :disabled="!selectedUsers.length" 
          color="primary" 
          @click="addSelectedMembers"
        >
          Add Selected
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

  
  <script>
  export default {
    name: 'AddTeamMemberDialog',
  
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      contactCards: {
        type: Array,
        default: () => [],
      },
      headers: {
        type: Array,
        required: true,
      },
      currentTeamUsers: {
        type: Array,
        default: () => [],
      },
    },
  
    data() {
      return {
        search: '',
        selectedUsers: [], // for multiple selection
      }
    },
  
    computed: {
      dialogModel: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
      filteredContactCards() {
      // First filter out users that are already in the team
      const availableContacts = this.contactCards.filter(contact => {
        return !this.currentTeamUsers.some(teamUser => 
          teamUser.contactCard.id === contact.contactCard.id
        );
      });

      // Then apply search filter
      if (!this.search) {
        return availableContacts;
      }
      
      const searchTerm = this.search.toLowerCase();
      return availableContacts.filter(contact => {
        const card = contact.contactCard;
        return (
          (card.firstName && card.firstName.toLowerCase().includes(searchTerm)) ||
          (card.surname && card.surname.toLowerCase().includes(searchTerm)) ||
          (card.jobTitle && card.jobTitle.toLowerCase().includes(searchTerm)) ||
          (card.email && card.email.toLowerCase().includes(searchTerm)) ||
          (card.stakeholder && card.stakeholder.friendlyName.toLowerCase().includes(searchTerm))
        );
      });
    },
  },
  
    methods: {
      getUserAvatar(profilePicture) {
        return profilePicture?.urlThumb || 'contact-placeholder.jpg'
      },
      addSelectedMembers() {
      this.$emit('add-member', this.selectedUsers); // Emit the selected users
      this.dialogModel = false;
      this.selectedUsers = []; // Clear selected users after adding
      this.search = '';
    },
    },
  }
  </script>