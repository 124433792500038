<!-- dialogs/CreateUserDialog.vue -->
<template>
    <v-dialog v-model="dialogModel" width="600">
      <v-card class="pa-4">
        <span>
          This contact does not have a user account, select a role to create a user account or cancel to go back
          <br>
          Note: An email with account instructions will be sent.
        </span>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedRoleModel"
              outlined
              label="Select Role For New User*"
              :items="roles"
              item-text="name"
              item-value="id"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="!selectedRoleModel"
            @click="$emit('create-user')"
          >
            Confirm
          </v-btn>
          <v-btn color="white" @click="$emit('cancel')">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'CreateUserDialog',
  
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      roles: {
        type: Array,
        default: () => [],
      },
      selectedRole: {
        type: [String, Number],
        default: null,
      },
    },
  
    computed: {
      dialogModel: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
      selectedRoleModel: {
        get() {
          return this.selectedRole
        },
        set(value) {
          this.$emit('update:selected-role', value)
        },
      },
    },
  }
  </script>